import { Checkbox, FormControlLabel } from '@material-ui/core';

const CustomChekbox = (props) => {

    return (
        <FormControlLabel
            control={<Checkbox
                color="primary"
                {...props}
            />}
            label={props.label}
        />

    )
}

export default CustomChekbox;