import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Snackbar } from "@material-ui/core";

import AuthPage from "./pages/AuthPage";
import MainPage from "./pages/MainPage";
import TasksPage from "./pages/TasksPage";
import ApprovementPage from "./pages/ApprovementPage";
import UsersPage from "./pages/UsersPage";
import ShopPage from "./pages/ShopPage";
import DataPage from "./pages/DataPage";
import NewsPage from "./pages/NewsPage";
import ProfilePage from "./pages/ProfilePage";
import FAQPage from "./pages/FAQPage";
import NotificationsPage from "./pages/NotificationsPage";
import Isolation from "./pages/Isolation";

import {
  ROUTE_AUTH,
  ROUTE_HOME,
  ROUTE_TASKS_PARAMS,
  ROUTE_TASKS_EDIT,
  ROUTE_USERS,
  ROUTE_SHOP,
  ROUTE_DATA,
  ROUTE_NEWS,
  ROUTE_APPROVEMENT,
  ROUTE_PROFILE,
  ROUTE_FAQ,
  ROUTE_NOTIFICATIONS,
  ROUTE_DATA_PHARMACY,
  ROUTE_SHOP_PRODUCTS,
  ROUTE_USERS_MEDICAL_MEMBERS,
  ROUTE_APPROVEMENT_BUY,
} from "./AppRoutes";
import { selectUserIsLoggedIn } from "./store/user";
import { selectAppState } from "./store/app";
import useDispatchError from "./shared/hooks/useDispatchError";

const loginRoutes = () => {
  return (
    <Switch>
      <Route path={ROUTE_AUTH}>
        <AuthPage />
      </Route>

      <Route>
        <Redirect to={ROUTE_AUTH} />
      </Route>
    </Switch>
  );
};

const appRoutes = () => {
  return (
    <Switch>
      <Route path={[ROUTE_TASKS_PARAMS, ROUTE_TASKS_EDIT]}>
        <TasksPage />
      </Route>

      <Route exact path={ROUTE_APPROVEMENT}>
        <Redirect to={ROUTE_APPROVEMENT_BUY} />
      </Route>
      <Route path={ROUTE_APPROVEMENT}>
        <ApprovementPage />
      </Route>

      <Route exact path={ROUTE_USERS}>
        <Redirect to={ROUTE_USERS_MEDICAL_MEMBERS} />
      </Route>
      <Route path={ROUTE_USERS}>
        <UsersPage />
      </Route>

      <Route exact path={"/shop"}>
        <Redirect to={ROUTE_SHOP_PRODUCTS} />
      </Route>
      <Route path={ROUTE_SHOP}>
        <ShopPage />
      </Route>

      <Route exact path={ROUTE_DATA}>
        <Redirect to={ROUTE_DATA_PHARMACY} />
      </Route>
      <Route path={ROUTE_DATA}>
        <DataPage />
      </Route>

      <Route path={ROUTE_NEWS}>
        <NewsPage />
      </Route>
      <Route path={ROUTE_NOTIFICATIONS}>
        <NotificationsPage />
      </Route>
      <Route path={ROUTE_FAQ}>
        <FAQPage />
      </Route>
      <Route path={ROUTE_PROFILE}>
        <ProfilePage />
      </Route>

      {/* <Route path='/isolation'>
        <Isolation />
      </Route> */}

      <Route exact path={ROUTE_HOME}>
        <MainPage />
      </Route>

      <Route path={ROUTE_AUTH}>
        <Redirect to={ROUTE_HOME} />
      </Route>

      <Route>
        <Redirect to={ROUTE_HOME} />
      </Route>
    </Switch>
  );
};

function App() {
  const { error } = useSelector(selectAppState);
  const [, clearError] = useDispatchError();
  const onCloseError = () => clearError();

  const isUserLoggedIn = useSelector(selectUserIsLoggedIn);
  const AppRoutes = isUserLoggedIn ? appRoutes : loginRoutes;

  return (
    <>
      <Snackbar
        key={error?.msg}
        open={!!error?.msg}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
        <Alert severity={error?.style} onClose={onCloseError}>
          <AlertTitle>{error?.msgTitle}</AlertTitle>
          <p className='app-error-msg'>{error?.msg}</p>
        </Alert>
      </Snackbar>

      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </>
  );
}

export default App;
