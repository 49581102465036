import { StoreActBase } from "..";
import isJWTExpired from "../../shared/functions/isJWTExpired";

export const StoreUserName = "user";
export const StoreUserDataStateName = "userData";
export interface StoreUserRoleData {
  _id: string;
  id: string;
  blocked: boolean;
  email: string;
  name: string;
  description: string;
  type: string;
  __v: number;
}
export type StoreUserDataState = {
  id: string;
  _id: string;
  username: string;
  provider: string;
  role: number;
  avatar: string;
  access_token: string;
  refresh_token: string;
} | null;

const localUser = localStorage.getItem("user");
const isLocalUser = !!localUser;
const localUserData = JSON.parse(isLocalUser ? (localUser as string) : "{}");
export const StoreUserDataDefault = isLocalUser
  ? isJWTExpired(localUserData.jwt)
    ? null
    : localUserData.user
  : null;

export type StoreUserState = {
  [StoreUserDataStateName]: StoreUserDataState;
};
export const StoreUserStateDefault: StoreUserState = {
  [StoreUserDataStateName]: StoreUserDataDefault
    ? { ...StoreUserDataDefault }
    : StoreUserDataDefault,
};

export const USER_LOGIN = "user/login";
export type StoreActUserLogin = StoreActBase<
  typeof USER_LOGIN,
  StoreUserDataState
>;

export const USER_LOGOUT = "user/logout";
export type StoreActUserLogout = StoreActBase<typeof USER_LOGOUT>;

export type StoreUserActs = StoreActUserLogin | StoreActUserLogout;
