import React from 'react';
import { Text, Spacer, Icon, Button } from '../common';
import {TextField, InputAdornment } from '@material-ui/core';
const LoginCard = ({onNext, onLogin}) => {

    return (
        <React.Fragment>
            <Text type="h4">Восстановление пароля</Text>
            <Spacer top="1rem" />
            <Text type="md" color="grey">Введите свой e-mail</Text>
            <Spacer top="5.5rem" />
            <TextField
                placeholder="Е-мейл"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Icon icon="mail" size="3rem" />
                        </InputAdornment>
                    ),
                }}
                type="email"
                variant="outlined" />
            <Spacer top="3rem" />
            <Button type="primary" onClick={onNext}>
                <Text isCenter type="md" color="white">Отправить</Text>
            </Button>
            <Spacer top="7.5rem" />
            <div
                onClick={onLogin}
                className="auth-link">
                Войти в систему
            </div>
        </React.Fragment>
    )
}

export default LoginCard;
