import React from 'react';
import { Text, Spacer, Icon, Button } from '../common';
import {TextField, InputAdornment } from '@material-ui/core';
const LoginCard = ({onLogin}) => {

    return (
        <React.Fragment>
            <Text type="h4">Введите новый пароль</Text>
            <Spacer top="4.25rem" />
            <TextField
                placeholder="Пароль"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Icon icon="lock" size="3rem" />
                        </InputAdornment>
                    ),
                }}
                type="password"
                variant="outlined" />
            <Spacer top="1.5rem" />
            <TextField
                placeholder="Повторите пароль"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Icon icon="lock" size="3rem" />
                        </InputAdornment>
                    ),
                }}
                type="password"
                variant="outlined" />
            <Spacer top="3rem" />
            <Button type="primary">
                <Text isCenter type="md" color="white">Сохранить</Text>
            </Button>
            <Spacer top="7.5rem" />
            <div
                onClick={onLogin}
                className="auth-link">
                Войти в систему
            </div>
        </React.Fragment>
    )
}

export default LoginCard;