import React, { useState } from "react";
import { Switch, Route, NavLink } from "react-router-dom";

import Layout from "../components/layout/Layout";
import { Tabs, Button, Spacer, Text, Icon, Input } from "../components/common";

import PharmacyTable from "../components/users/PharmacyTable";
import MedicalMembersTable from "../components/users/MedicalMembersTable";
import AdminTable from "../components/users/AdminTable";
import UserModal from "../components/users/UserModal";

import {
  ROUTE_USERS,
  ROUTE_USERS_MEDICAL_MEMBERS,
  ROUTE_USERS_PHARMACY,
  ROUTE_USERS_ADMIN,
  ROUTE_USERS_NEW,
  ROUTE_USERS_EDIT,
} from "../AppRoutes";
import useOnHistoryChange from "../shared/hooks/useOnHistoryChange";

const UsersPage = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const onChangeSearchQuery = _e => {
    _e?.preventDefault();
    const _value = _e?.target?.value;

    if (_value !== searchQuery) {
      setSearchQuery(_value);
    }
  };
  useOnHistoryChange(() => setSearchQuery(""));

  const tabHeaders = [
    <NavLink to={ROUTE_USERS_MEDICAL_MEMBERS}>Торг. представители</NavLink>,
    <NavLink to={ROUTE_USERS_PHARMACY}>Магазины</NavLink>,
    <NavLink to={ROUTE_USERS_ADMIN}>Администраторы</NavLink>,
  ];

  return (
    <Layout
      topSlot={
        <NavLink to={ROUTE_USERS_NEW}>
          <Button type='primary'>
            <Icon icon='add' size='2rem' />
            <Spacer left='0.5rem' />
            <Text type='sm' color='white'>
              Добавить пользователя
            </Text>
          </Button>
        </NavLink>
      }>
      <div className='users-page'>
        <Switch>
          <Route path={ROUTE_USERS_EDIT}>
            <UserModal />
          </Route>
          <Route path={ROUTE_USERS}>
            <Tabs
              isRouterTab
              header={tabHeaders}
              tabs={
                <React.Fragment>
                  <Route
                    path={ROUTE_USERS_MEDICAL_MEMBERS}
                    key={ROUTE_USERS_MEDICAL_MEMBERS}>
                    <MedicalMembersTable searchQuery={searchQuery} />
                  </Route>
                  <Route path={ROUTE_USERS_PHARMACY} key={ROUTE_USERS_PHARMACY}>
                    <PharmacyTable searchQuery={searchQuery} />
                  </Route>
                  <Route path={ROUTE_USERS_ADMIN} key={ROUTE_USERS_ADMIN}>
                    <AdminTable searchQuery={searchQuery} />
                  </Route>
                </React.Fragment>
              }
              topSlot={
                <Input
                  value={searchQuery}
                  onChange={onChangeSearchQuery}
                  placeholder='Поиск'
                  leftIcon={<Icon icon='search' size='2rem' />}
                />
              }
            />
          </Route>
        </Switch>
      </div>
    </Layout>
  );
};

export default UsersPage;
