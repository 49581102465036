import {
  API_DATA_AREAS,
  API_DATA_PROMO,
  API_DATA_PROMO_QUERY_AGENTS,
  API_DATA_PROMO_QUERY_PHARMACIES,
} from "../../../../services/api.service";
import {
  useFormData_TaskTypeMerch,
  useFormData_TaskTypePurchase,
  useFormData_TaskTypeSale,
  useFormData_TaskTypeTraining,
} from "../../../../shared/hooks/useFormData";

export const _defaultFormTypes = [
  {
    value: useFormData_TaskTypeMerch,
    text: "Мерчендайзинг",
  },
  {
    value: useFormData_TaskTypePurchase,
    text: "Закупка",
  },
  {
    value: useFormData_TaskTypeSale,
    text: "Продажа",
  },
  {
    value: useFormData_TaskTypeTraining,
    text: "Обучение",
  },
];

const __formOptionPropInit = (APIDataName, label, APIQuery = "") => ({
  APIDataName,
  APIQuery,
  label,
});

export const _formOptionsFetchProps = {
  [useFormData_TaskTypeMerch]: {
    areas: __formOptionPropInit(API_DATA_AREAS, "Зона выкладки"),
  },
  [useFormData_TaskTypePurchase]: {
    months: __formOptionPropInit(
      API_DATA_PROMO,
      "Акционные продукты (Магазины)",
      API_DATA_PROMO_QUERY_PHARMACIES,
    ),
  },
  [useFormData_TaskTypeSale]: {
    months: __formOptionPropInit(
      API_DATA_PROMO,
      "Акционные продукты (Торг. представители)",
      API_DATA_PROMO_QUERY_AGENTS,
    ),
  },
};

export const _formOptionsDefault = {
  [useFormData_TaskTypeMerch]: {
    areas: [],
  },
  [useFormData_TaskTypePurchase]: {
    months: [],
  },
  [useFormData_TaskTypeSale]: {
    months: [],
  },
};
export const usersDataNew = (id, repeats = 1) => ({ id, repeats });
