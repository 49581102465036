import { useState } from "react";
import { getImageURL } from "../../services/api.service";
import {
  convertDate,
  convertDateTime,
} from "../../shared/functions/convertDate";
import useDispatchError from "../../shared/hooks/useDispatchError";
import { Spacer, Text, Input, Select, Button, Icon, TextArea } from "../common";

const TableChildRowMerchApproveItem = ({
  openImagePreview,
  merch,
  task,
  pharmacy,
  onAction,
  isNeedAction,
  resultsLength,
  disabled,
}) => {
  const [setError] = useDispatchError();

  const imgThumbnail = merch?.picture?.formats?.thumbnail?.url;
  const imgRaw = merch?.picture?.url;
  const imgURL = imgThumbnail ?? imgRaw;

  const _isApproved = typeof merch.approved !== "undefined";
  const _scores = parseInt(
    _isApproved
      ? merch?.scores
      : parseInt(task?.merchandising?.rewardpharmacy) / resultsLength,
  );
  const [formData, setFormData] = useState({
    brand: merch.brand,
    area: merch.area,
    comment: _isApproved ? merch?.comment : "",
    scores: typeof _scores !== "number" ? 0 : _scores,
  });
  const brandVariants = task?.brands.map(brand => {
    return { value: brand.id, text: brand.name };
  });
  const areaVariants = task?.merchandising.areas.map(area => {
    return { value: area.id, text: area.name };
  });

  const handleApprove = () => {
    onAction(formData, true);
  };
  const handleDecline = () => {
    if (!formData?.comment?.length) {
      setError("Укажите комментарий.");
    } else {
      onAction({ ...formData, scores: 0 }, false);
    }
  };
  const handleFormChange = (key, value) => {
    let newForm = { ...formData };
    newForm[key] = value;
    setFormData(newForm);
  };

  const types = {
    contract: "Контракт",
    individual: "Индивидуальная",
    commercial: "Мелкая Сеть",
  };

  return (
    <div className='approvement-child-row'>
      <div
        className='imgWrapper'
        onClick={e => {
          e.preventDefault();
          if (imgRaw?.length) {
            openImagePreview(getImageURL(imgRaw));
          }
        }}>
        {imgURL ? (
          <img src={getImageURL(imgURL)} alt={merch.picture.name} />
        ) : null}
      </div>

      <Spacer left='3rem' />

      <div className='flex-1'>
        <div className='d-flex'>
          <div className='flex-1'>
            <div className='d-flex align-center'>
              <Text color='grey'>Начисленно</Text>
              <Spacer left='auto' />
              <Input
                type='number'
                disabled={!isNeedAction}
                value={formData.scores}
                onChange={e => handleFormChange("scores", e.target.value)}
              />
            </div>
            <Spacer top='1rem' />

            <div className='d-flex align-center'>
              <Text color='grey'>Дата</Text>
              <Spacer left='13rem' />
              <Text>{convertDate(merch.published_at)}</Text>
            </div>
            <Spacer top='1rem' />

            <div className='d-flex align-center'>
              <Text color='grey'>Время</Text>
              <Spacer left='11.75rem' />
              <Text>{convertDateTime(merch.published_at)}</Text>
            </div>
            <Spacer top='1rem' />

            <div className='d-flex align-center'>
              <Text color='grey'>Бренд</Text>
              <Spacer left='auto' />
              <Select
                disabled={!isNeedAction || disabled}
                value={formData.brand}
                onChange={e => handleFormChange("brand", e.target.value)}
                variants={brandVariants}
              />
            </div>
            <Spacer top='0.5rem' />
            <div className='d-flex align-center'>
              <Text color='grey'>Зона выкладки</Text>
              <Spacer left='auto' />
              <Select
                disabled={!isNeedAction || disabled}
                value={formData.area}
                onChange={e => handleFormChange("area", e.target.value)}
                variants={areaVariants}
              />
            </div>
          </div>

          <Spacer left='15rem' />

          <div className='flex-1 d-flex column'>
            <div className='d-flex align-center'>
              <Text color='grey'>Категория магазина</Text>
              <Spacer left='auto' />
              <Input disabled value={pharmacy?.category} />
            </div>
            <Spacer top='0.5rem' />
            <div className='d-flex align-center'>
              <Text color='grey'>Тип магазина</Text>
              <Spacer left='auto' />
              <Input disabled value={types[pharmacy?.type]} />
            </div>
            <Spacer top='auto' />

            {isNeedAction && (
              <div className='d-flex'>
                <Spacer left='auto' />

                <Button
                  type='outline'
                  onClick={handleDecline}
                  disabled={disabled}>
                  <Icon icon='close-outline' size='2.25rem' />
                  <Spacer left='1rem' />
                  <Text type='sm'>Отклонить</Text>
                </Button>

                <Spacer left='1.5rem' />

                <Button
                  type='primary'
                  onClick={handleApprove}
                  disabled={disabled}>
                  <Text type='sm'>Подтвердить</Text>
                </Button>
              </div>
            )}
          </div>
        </div>

        <Spacer top='2rem' />

        <TextArea
          disabled={!isNeedAction || disabled}
          placeholder='Напишите комментарий'
          style={{ width: "100%" }}
          rowsMin='1'
          value={formData.comment}
          onChange={e => handleFormChange("comment", e.target.value)}
        />
      </div>
    </div>
  );
};

export default TableChildRowMerchApproveItem;
