export const ROUTE_HOME = "/";

export const ROUTE_AUTH = "/auth";

export const ROUTE_TASKS = "/tasks";
export const ROUTE_TASKS_PARAMS = `${ROUTE_TASKS}/:status?`;
export const ROUTE_TASKS_DRAFTS = `/tasks/draft`;
export const ROUTE_TASKS_ACTIVES = `/tasks/active`;
export const ROUTE_TASKS_NEW = `${ROUTE_TASKS}_new`;
export const ROUTE_TASKS_EDIT = `${ROUTE_TASKS_NEW}/:id?`;

export const ROUTE_APPROVEMENT = "/approvement";
export const ROUTE_APPROVEMENT_BUY = `${ROUTE_APPROVEMENT}/buy`;
export const ROUTE_APPROVEMENT_SELL = `${ROUTE_APPROVEMENT}/sell`;
export const ROUTE_APPROVEMENT_MERCH = `${ROUTE_APPROVEMENT}/merch`;
export const ROUTE_APPROVEMENT_STUDY = `${ROUTE_APPROVEMENT}/study`;

export const ROUTE_USERS = "/users";
export const ROUTE_USERS_MEDICAL_MEMBERS = `${ROUTE_USERS}/medical-members`;
export const ROUTE_USERS_PHARMACY = `${ROUTE_USERS}/pharmacy`;
export const ROUTE_USERS_ADMIN = `${ROUTE_USERS}/admin`;
export const ROUTE_USERS_NEW = `${ROUTE_USERS}/new`;
export const ROUTE_USERS_EDIT = `${ROUTE_USERS_NEW}/:id?`;

export const ROUTE_SHOP = "/shop/:section";
export const ROUTE_SHOP_SECTION_PRODUCTS = "products";
export const ROUTE_SHOP_PRODUCTS = `/shop/${ROUTE_SHOP_SECTION_PRODUCTS}`;
export const ROUTE_SHOP_PRODUCTS_NEW = `${ROUTE_SHOP_PRODUCTS}/new`;
export const ROUTE_SHOP_PRODUCTS_EDIT = `${ROUTE_SHOP_PRODUCTS_NEW}/:id?`;
export const ROUTE_SHOP_SECTION_ORDERS = "orders";
export const ROUTE_SHOP_ORDERS = `/shop/${ROUTE_SHOP_SECTION_ORDERS}`;
export const ROUTE_SHOP_SECTION_GROUPS = "groups";
export const ROUTE_SHOP_GROUPS = `/shop/${ROUTE_SHOP_SECTION_GROUPS}`;
export const ROUTE_SHOP_GROUPS_NEW = `${ROUTE_SHOP_GROUPS}/new`;
export const ROUTE_SHOP_GROUPS_EDIT = `${ROUTE_SHOP_GROUPS_NEW}/:id?`;
export const ROUTE_EDIT = "/edit";

export const ROUTE_NOTIFICATIONS = "/notifications";
export const ROUTE_NOTIFICATIONS_NEW = `${ROUTE_NOTIFICATIONS}/new`;
export const ROUTE_NOTIFICATIONS_EDIT = `${ROUTE_NOTIFICATIONS_NEW}/:id?`;

export const ROUTE_NEWS = "/news";
export const ROUTE_NEWS_NEW = `${ROUTE_NEWS}/new`;
export const ROUTE_NEWS_EDIT = `${ROUTE_NEWS_NEW}/:id?`;

export const ROUTE_FAQ = "/faq";
export const ROUTE_FAQ_NEW = `${ROUTE_FAQ}/new`;
export const ROUTE_FAQ_EDIT = `${ROUTE_FAQ_NEW}/:id?`;

export const ROUTE_PROFILE = "/profile";

export const ROUTE_DATA = "/data";
export const ROUTE_DATA_PHARMACY = `${ROUTE_DATA}/pharmacy`;
export const ROUTE_DATA_MEDICAL_MEMBERS = `${ROUTE_DATA}/medical-members`;
export const ROUTE_DATA_DISTRIBUTORS = `${ROUTE_DATA}/distributors`;
export const ROUTE_DATA_MEDICAL_MEMBERS_LINES = `${ROUTE_DATA}/medical-members-lines`;
export const ROUTE_DATA_TEAMS = `${ROUTE_DATA}/teams`;
