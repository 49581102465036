import queryStringify from "qs-stringify";
import React, { useEffect } from "react";
import { API_DATA_ENTITIES } from "../../services/api.service";
import useAPIData from "../../shared/hooks/useAPIData";
import { Table, Text, Button, Icon } from "../common";
import TableChildRowPharmacy from "./TableChildRowPharmacy";

const PharmacyTable = ({ searchQuery }) => {
  const _getFiltersQueries = () => {
    const _filters = {};

    if (searchQuery?.length) {
      _filters._q = searchQuery;
    }

    const qs = queryStringify(_filters);
    return qs;
  };

  const [pharmacies, pagination, updatePharmacies, , onChangePage] = useAPIData(
    API_DATA_ENTITIES,
    _getFiltersQueries(),
  );

  const _pagination = {
    ...pagination,
    onChange: page => onChangePage(page, _getFiltersQueries()),
  };

  useEffect(() => {
    if (pharmacies) {
      updatePharmacies(_getFiltersQueries());
    }
  }, [searchQuery]);

  const headers = [
    { title: "Название аптеки", isSortable: false },
    { title: "Тип аптеки", isSortable: false },
    { title: "БИН", isSortable: false },
    { title: "Телефон", isSortable: false },
    { title: "Номер договора", isSortable: false },
    { title: "Баллы", isSortable: false },
    { title: "Количество аптек", isSortable: false },
    { title: "", isSortable: false },
  ];
  const types = {
    contract: "Контракт",
    individual: "Индивидуальная",
    commercial: "Мелкая Сеть",
  };

  const row = item => {
    let pharmacyType = "Неизвестно";
    if (
      item?.pharmacies?.length &&
      item?.pharmacies[0]?.type &&
      types[item?.pharmacies[0]?.type]
    ) {
      pharmacyType = types[item?.pharmacies[0]?.type];
    }

    let scores = 0;
    if (item?.pharmacies?.length) {
      for (const ph of item?.pharmacies) {
        if (ph?.main) {
          scores = ph?.scores ?? -1;
        }

        if (0 !== scores) {
          break;
        }
      }
    }

    return (
      <React.Fragment>
        <div className='table-row__cell'>
          <Text>{item?.entityname ?? "Неизвестно"}</Text>
        </div>
        <div className='table-row__cell'>
          <Text>{pharmacyType}</Text>
        </div>
        <div className='table-row__cell'>
          <Text>{item?.bin ?? "Неизвестно"}</Text>
        </div>
        <div className='table-row__cell'>
          <Text>{item?.entityphone ?? "Неизвестно"}</Text>
        </div>
        <div className='table-row__cell'>
          <Text>{item?.contract ?? "Неизвестно"}</Text>
        </div>
        <div className='table-row__cell'>
          <Text>{scores}</Text>
        </div>
        <div className='table-row__cell'>
          <Text>{item?.pharmacies?.length ?? 0}</Text>
        </div>
      </React.Fragment>
    );
  };

  return (
    <div className='card flex-1 '>
      <Table
        // columns={['0.8fr', '1fr', '1fr', ' 0.75fr', '0.5fr', '0.5fr', '0.9fr', '0.9fr']}
        columns={[
          "30rem",
          "15rem",
          "17rem",
          "18rem",
          "16rem",
          "16rem",
          "23rem",
          "6rem",
        ]}
        headers={headers}
        pagination={_pagination}
        row={row}
        collapseSlot={
          <Button type='flat'>
            <Icon icon='arrow-down' size='2.5rem' />
          </Button>
        }
        childrenRow={item => <TableChildRowPharmacy item={item} />}
        list={pharmacies}
        isLoading={!pharmacies}
      />
    </div>
  );
};

export default PharmacyTable;
