import React, { useEffect } from "react";
import { Table, Text, Icon, Spacer, Button } from "../common";
import TableChildRowMerch from "./TableChildRowMerch";

import useAPIData from "../../shared/hooks/useAPIData";
import {
  convertDate,
  convertDateTime,
} from "../../shared/functions/convertDate";
import queryStringify from "qs-stringify";

const ApproveMerchTable = ({ searchQuery }) => {
  const _getFiltersQueries = () => {
    const __filters_queries_names = [
      "useragent.firstname",
      "useragent.lastname",
      "userpharmacy.firstname",
      "userpharmacy.lastname",
      "userpharmacy.pharmacy.pharmacyname",
    ];
    let _filters = { status: "wait", _sort: "updatedAt:DESC" };

    if (searchQuery?.length) {
      _filters._where = {
        _or: __filters_queries_names.map(_f => {
          const _fname = _f + "_contains";
          return [{ [_fname]: searchQuery }];
        }),
      };
    }

    const qs = queryStringify(_filters);
    return qs;
  };
  const [tasks, pagination, updateTasks, , onChangePage] = useAPIData(
    "taskmerchandisingusers",
    _getFiltersQueries(),
  );

  const _pagination = {
    ...pagination,
    onChange: page => onChangePage(page, _getFiltersQueries()),
  };

  useEffect(() => {
    if (tasks) {
      updateTasks(_getFiltersQueries());
    }
  }, [searchQuery]);

  const headers = [
    { title: "", isSortable: false },
    { title: "Имя", isSortable: false },
    { title: "Телефон", isSortable: false },
    { title: "Адрес", isSortable: false },
    { title: "Телефон", isSortable: false },
    { title: "Название", isSortable: false },
    { title: "Адрес", isSortable: false },
    { title: "Дата отправки", isSortable: false },
    { title: "", isSortable: false },
  ];
  const handleSaveAction = () => {
    updateTasks(`status=wait`);
  };

  const row = item => (
    <React.Fragment>
      <div
        className={`table-row__cell status-icon__cell ${
          item?.status ? "active" : ""
        }`}>
        {item?.status ? (
          <Icon icon='primary-forward' size='1.75rem' />
        ) : (
          <Icon icon='close-outline' size='1.75rem' />
        )}
      </div>
      <div className='table-row__cell'>
        <div>
          <Text>
            {item?.useragent?.firstname && item?.useragent?.lastname
              ? `${item?.useragent?.firstname} ${item?.useragent?.lastname}`
              : "Не указано"}
          </Text>
          <Text type='caption' color='primary'>
            <a title={item?.id ?? "Неизвестно"}>
              id{" "}
              {!item?.id?.length ? "Неизвестно" : `${item.id.slice(0, 10)}...`}
            </a>
          </Text>
        </div>
      </div>
      <div className='table-row__cell'>
        <Text>{item?.useragent?.username ?? "Неизвестно"}</Text>
      </div>
      <div className='table-row__cell'>
        <Text>{item?.useragent?.agent?.address || "---"}</Text>
      </div>
      <div className='table-row__cell'>
        <Text>{item?.userpharmacy?.username ?? "Неизвестно"}</Text>
      </div>
      <div className='table-row__cell'>
        <Text>
          {item?.userpharmacy?.pharmacy?.pharmacyname ?? "Неизвестно"}
        </Text>
      </div>
      <div className='table-row__cell'>
        <Text>{item?.userpharmacy?.pharmacy?.address || "---"}</Text>
      </div>
      <div className='table-row__cell'>
        <div>
          <Text isBold>{convertDate(item?.published_at)}</Text>
          <Spacer top='0.25rem' />
          <Text>{convertDateTime(item?.published_at)}</Text>
        </div>
      </div>
    </React.Fragment>
  );

  return (
    <div className='card flex-1 merch-table'>
      <div className='approve-merch-table_header'>
        <div className='approve-merch-table_header__item'>
          Торговый представитель
        </div>
        <div className='approve-merch-table_header__item'>Магазин</div>
      </div>

      <Table
        // columns={['4rem', '0.75fr', '0.5fr', '0.75fr', '0.75fr', '0.75fr', '1fr', '0.75fr', '0.75fr']}
        columns={[
          "5rem",
          "18rem",
          "17rem",
          "18rem",
          "17rem",
          "17rem",
          "18rem",
          "13rem",
          "17rem",
        ]}
        headers={headers}
        pagination={_pagination}
        row={row}
        onCloseCollapseSlot={() => updateTasks(_getFiltersQueries())}
        collapseSlot={
          <Button type='primary-outline'>
            Проверить
            <Spacer left='1.5rem' />
            <Icon icon='arrow-down' size='2.5rem' />
          </Button>
        }
        childrenRow={item => (
          <TableChildRowMerch merch={item} onSave={() => handleSaveAction()} />
        )}
        list={tasks}
        isLoading={!tasks}
      />
    </div>
  );
};

export default ApproveMerchTable;
