import React from "react";
import Logo from "../../assets/logo-dark.svg";
import { Text, Spacer, Icon, Avatar } from "../common";
import { NavLink, Link } from "react-router-dom";

import {
  ROUTE_HOME,
  ROUTE_TASKS,
  ROUTE_USERS_MEDICAL_MEMBERS,
  ROUTE_DATA_PHARMACY,
  ROUTE_SHOP_PRODUCTS,
  ROUTE_APPROVEMENT_BUY,
} from "../../AppRoutes";
import { useSelector } from "react-redux";
import { selectUserData } from "../../store/user";

const Menu = () => {
  const userData = useSelector(selectUserData);

  let userName = "Неизвестно";
  if (userData?.firstname && userData?.lastname) {
    userName = `${userData?.firstname} ${userData?.lastname}`;
  }

  return (
    <div className='page-menu'>
      <div className='logo-wrapper'>
        <img className='logo' src={Logo} alt='Logo' />
      </div>

      <div className='menu-card'>
        <NavLink to={ROUTE_HOME} exact className='menu-item'>
          <Icon icon='menu' size='2.5rem' />
          <Spacer left='2rem' />
          <div>Главная</div>
        </NavLink>
        <NavLink
          to={ROUTE_TASKS}
          className='menu-item'
          isActive={(match, location) => {
            return location.pathname.startsWith("/tasks");
          }}>
          <Icon icon='document' size='2.5rem' />
          <Spacer left='2rem' />
          <div>Задания</div>
        </NavLink>
        <NavLink
          to={ROUTE_APPROVEMENT_BUY}
          isActive={(match, location) => {
            return location.pathname.split("/")[1] === "approvement";
          }}
          className='menu-item'>
          <Icon icon='done-outline' size='2.5rem' />
          <Spacer left='2rem' />
          <div>Подтверждение</div>
        </NavLink>
        <NavLink
          to={ROUTE_USERS_MEDICAL_MEMBERS}
          isActive={(match, location) => {
            return location.pathname.split("/")[1] === "users";
          }}
          className='menu-item'>
          <Icon icon='user' size='2.5rem' />
          <Spacer left='2rem' />
          <div>Пользователи</div>
        </NavLink>
        <NavLink
          to={ROUTE_SHOP_PRODUCTS}
          isActive={(match, location) => {
            return location.pathname.split("/")[1] === "shop";
          }}
          className='menu-item'>
          <Icon icon='shop' size='2.5rem' />
          <Spacer left='2rem' />
          <div>Магазин</div>
        </NavLink>
        <NavLink
          to={ROUTE_DATA_PHARMACY}
          isActive={(match, location) => {
            return location.pathname.split("/")[1] === "data";
          }}
          className='menu-item'>
          <Icon icon='data-chart' size='2.5rem' />
          <Spacer left='2rem' />
          <div>Управление данными</div>
        </NavLink>
      </div>

      <div className='menu-bottom'>
        <NavLink to='/notifications' exact className='menu-item'>
          <Icon icon='bell' size='2.5rem' />
          <Spacer left='2rem' />
          <div>Уведомления</div>
        </NavLink>
        <NavLink to='/news' exact className='menu-item'>
          <Icon icon='news' size='2.5rem' />
          <Spacer left='2rem' />
          <div>Новости</div>
        </NavLink>
        <NavLink to='/faq' exact className='menu-item'>
          <Icon icon='info' size='2.5rem' />
          <Spacer left='2rem' />
          <div>FAQ</div>
        </NavLink>

        <div className='bottom-card'>
          <Avatar />
          <Spacer left='1rem' />
          <div>
            <Text type='sm' color='black'>
              {userName}
            </Text>
            <Text type='caption' color='primary'>
              {userData?.role?.description ?? "Неизвестно"}
            </Text>
          </div>
          {/* <Spacer left='auto' />
          <Link to='/profile'>
            <Icon icon='arrow-right' size='3rem' />
          </Link> */}
        </div>
      </div>
    </div>
  );
};

export default Menu;
