import React, { useState } from "react";
import Layout from "../components/layout/Layout";
import { Switch, Route, NavLink, useParams } from "react-router-dom";
import { Tabs, Button, Spacer, Text, Icon, Input } from "../components/common";

import {
  ProductsTable,
  ProductModal,
  OrdersTable,
  GroupsTable,
  GroupModal,
} from "../components/shop";

import {
  ROUTE_SHOP_PRODUCTS,
  ROUTE_SHOP_ORDERS,
  ROUTE_SHOP_GROUPS,
  ROUTE_SHOP_SECTION_PRODUCTS,
  ROUTE_SHOP_SECTION_ORDERS,
  ROUTE_SHOP_SECTION_GROUPS,
  ROUTE_SHOP_PRODUCTS_NEW,
  ROUTE_SHOP_PRODUCTS_EDIT,
  ROUTE_SHOP_GROUPS_NEW,
  ROUTE_SHOP_GROUPS_EDIT,
} from "../AppRoutes";
import useOnHistoryChange from "../shared/hooks/useOnHistoryChange";

const getPageTopSlotComponent = section =>
  ({
    [ROUTE_SHOP_SECTION_PRODUCTS]: (
      <NavLink to={ROUTE_SHOP_PRODUCTS_NEW}>
        <Button type='primary'>
          <Icon icon='add' size='2rem' />
          <Spacer left='0.5rem' />
          <Text type='sm' color='white'>
            Добавить товар
          </Text>
        </Button>
      </NavLink>
    ),
    [ROUTE_SHOP_SECTION_ORDERS]: null,
    [ROUTE_SHOP_SECTION_GROUPS]: (
      <NavLink to={ROUTE_SHOP_GROUPS_NEW}>
        <Button type='primary'>
          <Icon icon='add' size='2rem' />
          <Spacer left='0.5rem' />
          <Text type='sm' color='white'>
            Добавить Группу
          </Text>
        </Button>
      </NavLink>
    ),
  }[section]);

const ShopPage = () => {
  const { section } = useParams();
  const tabHeaders = [
    <NavLink to={ROUTE_SHOP_PRODUCTS}>Товары</NavLink>,
    <NavLink to={ROUTE_SHOP_ORDERS}>Заказы</NavLink>,
    <NavLink to={ROUTE_SHOP_GROUPS}>Группы</NavLink>,
  ];

  const [searchQuery, setSearchQuery] = useState("");
  const onChangeSearchQuery = _e => {
    _e?.preventDefault();
    const _value = _e?.target?.value;

    if (_value !== searchQuery) {
      setSearchQuery(_value);
    }
  };

  const getTabsTopSlotComponent = section =>
    ({
      [ROUTE_SHOP_SECTION_PRODUCTS]: (
        <Input
          value={searchQuery}
          onChange={onChangeSearchQuery}
          placeholder='Поиск'
          leftIcon={<Icon icon='search' size='2rem' />}
        />
      ),
      [ROUTE_SHOP_SECTION_ORDERS]: (
        <React.Fragment>
          <Button type='primary-outline'>
            <Icon icon='download' size='2rem' />
            <Spacer left='0.5rem' />
            <Text type='sm' color='white'>
              Скачать
            </Text>
          </Button>
          <Spacer left='2rem' />
          <Input
            value={searchQuery}
            onChange={onChangeSearchQuery}
            placeholder='Поиск'
            leftIcon={<Icon icon='search' size='2rem' />}
          />
        </React.Fragment>
      ),
      [ROUTE_SHOP_SECTION_GROUPS]: null,
    }[section]);

  const pageTopSlot = getPageTopSlotComponent(section);
  const tabsTopSlot = getTabsTopSlotComponent(section);

  useOnHistoryChange(() => setSearchQuery(""));

  return (
    <Layout topSlot={pageTopSlot}>
      <div className='shop-page'>
        <Switch>
          {/* NEW PRODUCT */}
          <Route path={ROUTE_SHOP_PRODUCTS_EDIT} key={ROUTE_SHOP_PRODUCTS_EDIT}>
            <ProductModal isNew />
          </Route>
          {/* NEW CATEGORY */}
          <Route path={ROUTE_SHOP_GROUPS_EDIT} key={ROUTE_SHOP_GROUPS_EDIT}>
            <GroupModal isNew />
          </Route>
          <Tabs
            isRouterTab
            header={tabHeaders}
            tabs={
              <React.Fragment>
                <Route
                  exact
                  path={ROUTE_SHOP_PRODUCTS}
                  key={ROUTE_SHOP_PRODUCTS}>
                  <ProductsTable searchQuery={searchQuery} />
                </Route>
                <Route exact path={ROUTE_SHOP_ORDERS} key={ROUTE_SHOP_ORDERS}>
                  <OrdersTable searchQuery={searchQuery} />
                </Route>
                <Route exact path={ROUTE_SHOP_GROUPS} key={ROUTE_SHOP_GROUPS}>
                  <GroupsTable />
                </Route>
              </React.Fragment>
            }
            topSlot={tabsTopSlot}
          />
        </Switch>
      </div>
    </Layout>
  );
};

export default ShopPage;
