import React, { useState } from "react";
import APIService, {
  API_REPORTS,
  API_UPLOAD_DATA_MAX_SIZE,
} from "../../services/api.service";
import useDispatchError from "../../shared/hooks/useDispatchError";
import { StoreAppErrorTypeForm } from "../../store/app";
import {
  Table,
  Text,
  Icon,
  Spacer,
  Select,
  Button,
  DatePicker,
  Dialog,
  FileInput,
} from "../common";
import { FileInputAcceptFilesExcel } from "../common/inputs/FileInput";
import TableChildRowHand from "./TableChildRowHand";
import TableChildRowPhoto from "./TableChildRowPhoto";

const ApproveSellTable = () => {
  const [setError] = useDispatchError();

  const headers = [
    { title: "", isSortable: false },
    { title: "Торг.представитель", isSortable: false },
    { title: "Магазин", isSortable: false },
    { title: "Телефон", isSortable: false },
    { title: "Дистрибьютор", isSortable: false },
    { title: "Накладная", isSortable: false },
    { title: "Тип подтверждения", isSortable: false },
    { title: "Дата накладной", isSortable: false },
    { title: "Баллы", isSortable: false },
    { title: "", isSortable: false },
  ];

  const row = item => (
    <React.Fragment>
      <div
        className={`table-row__cell status-icon__cell ${
          item.status ? "active" : ""
        }`}>
        {item.status ? (
          <Icon icon='done-outline' size='1.75rem' />
        ) : (
          <Icon icon='close-outline' size='1.75rem' />
        )}
      </div>
      <div className='table-row__cell'>
        <div>
          <Text>{item.medicalPerson.name}</Text>
          <Text type='caption' color='primary'>
            id {item.medicalPerson.id}
          </Text>
        </div>
      </div>
      <div className='table-row__cell'>
        <Text>{item.contract}</Text>
      </div>
      <div className='table-row__cell'>
        <Text>{item.phone}</Text>
      </div>
      <div className='table-row__cell'>
        <Text>{item.distributor}</Text>
      </div>
      <div className='table-row__cell'>
        <Text>{item.numberOfDoc}</Text>
      </div>
      <div className='table-row__cell'>
        <Text>{item.approvementType}</Text>
      </div>
      <div className='table-row__cell'>
        <Text>{item.date}</Text>
      </div>
      <div className='table-row__cell'>
        <Text>{item.mark}</Text>
      </div>
    </React.Fragment>
  );
  const list = [
    {
      status: true,
      medicalPerson: {
        name: "Ип Марал ",
        id: "44802",
      },
      contract: "00155",
      phone: "380 (93) 111 11 11",
      distributor: "Рауза",
      numberOfDoc: 1,
      approvementType: "Фото",
      date: "04-01-2021",
    },
  ];

  const [uploadDialog, setUploadDialog] = useState(false);
  const onOpenUploadDialog = () => setUploadDialog(true);
  const onCloseUploadDialog = () => setUploadDialog(false);
  const [file, setFile] = useState(undefined);
  const onChangeFile = f => {
    if (f?.size > API_UPLOAD_DATA_MAX_SIZE) {
      setError("Максимальный размер файла 5Мб.", StoreAppErrorTypeForm);
      return;
    }
    setFile(f);
  };

  const onSubmitUploadFile = async () => {
    let isErrSet = false;
    const uploadedData = await APIService.postUpload(file).then(
      resp => resp.data,
      err => {
        isErrSet = true;
        setError("Не удалось загрузить Данные.");
      },
    );

    if (uploadedData?.length && uploadedData[0]) {
      const docUploadData = { document: uploadedData[0].id };
      const isSuccess = await APIService.postDataHandler(
        API_REPORTS,
        docUploadData,
        () => setError(`Не удалось загрузить Данные.`),
      );

      if (isSuccess) {
        onCloseUploadDialog();
      }
    } else {
      if (!isErrSet) {
        setError("Не удалось получить загруженные Данные.");
      }
    }
  };

  return (
    <>
      <div className='card flex-1'>
        <div className='table-filters'>
          <Select
            label='Дистрибютор'
            value=''
            onChange={() => {}}
            variants={[{ value: "Все", text: "Все" }]}
          />

          <Spacer left='1.5rem' />

          <DatePicker
            label='Дата публикации'
            value={null}
            onChange={() => {}}
          />

          <Spacer left='auto' />
          <Button type='primary-outline' onClick={() => onOpenUploadDialog()}>
            <Icon icon='download' size='2.5rem' />
            <Spacer left='1.5rem' />
            Загрузить отчет
          </Button>
        </div>

        <Table
          // columns={['3rem', '1fr', '0.5fr', '1fr', '0.75fr', '0.75fr', '1.25fr', '1fr', '0.75fr', '0.75fr']}
          columns={[
            "5rem",
            "18rem",
            "10rem",
            "16rem",
            "15rem",
            "16rem",
            "20rem",
            "16rem",
            "6rem",
            "18rem",
          ]}
          headers={headers}
          // pagination={{
          //     ...pagination,
          //     onChange: (value) => onChangePaginationHandler(value)
          // }}
          row={row}
          collapseSlot={
            <Button type='primary-outline'>
              Проверить
              <Spacer left='1.5rem' />
              <Icon icon='arrow-down' size='2.5rem' />
            </Button>
          }
          childrenRow={item => {
            if (item.approvementType === "Фото") {
              return <TableChildRowPhoto />;
            } else {
              return <TableChildRowHand />;
            }
          }}
          list={list}
          isLoading={true}></Table>
      </div>

      <Dialog show={uploadDialog} close={onCloseUploadDialog}>
        <form style={{ width: "67.5rem" }}>
          <Text type='md' color='secondary'>
            Добавление документа
          </Text>

          <Spacer top='1.5rem' />

          <FileInput
            accept={FileInputAcceptFilesExcel}
            value={file}
            onChange={onChangeFile}
            required={true}
          />

          <Spacer top='1.5rem' />

          <Text isCenter>Разрешенные типы файлов: .xls и .xlsx</Text>

          <Spacer top='1.5rem' />

          <div className='d-flex justify-center'>
            <Button
              type='primary'
              onClick={e => {
                e?.preventDefault();
                onSubmitUploadFile();
              }}>
              <Text type='sm' color='white'>
                Загрузить
              </Text>
            </Button>
          </div>
        </form>
      </Dialog>
    </>
  );
};

export default ApproveSellTable;
