import ApproveSellTable from "../components/approvement/ApproveSellTable";
import ApproveBuyTable from "../components/approvement/ApproveBuyTable";
import ApproveMerchTable from "../components/approvement/ApproveMerchTable";
import ApproveStudyTable from "../components/approvement/ApproveStudyTable";
import Layout from "../components/layout/Layout";
import { Tabs, Input, Icon } from "../components/common";
import { Route, NavLink } from "react-router-dom";
import {
  ROUTE_APPROVEMENT_BUY,
  ROUTE_APPROVEMENT_SELL,
  ROUTE_APPROVEMENT_MERCH,
  ROUTE_APPROVEMENT_STUDY,
} from "../AppRoutes";
import { useState } from "react";
import useOnHistoryChange from "../shared/hooks/useOnHistoryChange";

const ApprovementPage = () => {
  const tabHeaders = [
    <NavLink to={ROUTE_APPROVEMENT_BUY} key={ROUTE_APPROVEMENT_BUY}>
      Закупка
    </NavLink>,
    <NavLink to={ROUTE_APPROVEMENT_SELL} key={ROUTE_APPROVEMENT_SELL}>
      Продажа
    </NavLink>,
    <NavLink to={ROUTE_APPROVEMENT_MERCH} key={ROUTE_APPROVEMENT_MERCH}>
      Мерчендайзинг
    </NavLink>,
    <NavLink to={ROUTE_APPROVEMENT_STUDY} key={ROUTE_APPROVEMENT_STUDY}>
      Обучение
    </NavLink>,
  ];

  const [searchQuery, setSearchQuery] = useState("");
  const onChangeSearchQuery = _e => {
    _e?.preventDefault();
    const _value = _e?.target?.value;

    if (_value !== searchQuery) {
      setSearchQuery(_value);
    }
  };
  useOnHistoryChange(() => setSearchQuery(""));

  return (
    <Layout>
      <div className='approvement-page'>
        <Tabs
          isRouterTab
          header={tabHeaders}
          tabs={[
            <Route
              exact
              path={ROUTE_APPROVEMENT_BUY}
              key={ROUTE_APPROVEMENT_BUY}>
              <ApproveBuyTable searchQuery={searchQuery} />,
            </Route>,
            <Route
              exact
              path={ROUTE_APPROVEMENT_SELL}
              key={ROUTE_APPROVEMENT_SELL}>
              <ApproveSellTable searchQuery={searchQuery} />,
            </Route>,
            <Route
              exact
              path={ROUTE_APPROVEMENT_MERCH}
              key={ROUTE_APPROVEMENT_MERCH}>
              <ApproveMerchTable searchQuery={searchQuery} />,
            </Route>,
            <Route
              exact
              path={ROUTE_APPROVEMENT_STUDY}
              key={ROUTE_APPROVEMENT_STUDY}>
              <ApproveStudyTable searchQuery={searchQuery} />
            </Route>,
          ]}
          topSlot={
            <Input
              value={searchQuery}
              onChange={onChangeSearchQuery}
              placeholder='Поиск'
              leftIcon={<Icon icon='search' size='2rem' />}
            />
          }
        />
      </div>
    </Layout>
  );
};

export default ApprovementPage;
