import { StoreSelectorBase } from "..";

import {
  StoreUserState,
  StoreUserName,
  StoreUserDataState,
  StoreUserDataStateName,
} from "./userTypes";

export const selectUserState: StoreSelectorBase<StoreUserState> = s =>
  s && s[StoreUserName];
export const selectUserData: StoreSelectorBase<StoreUserDataState> = s => {
  const userState = selectUserState(s);
  return userState && userState[StoreUserDataStateName]
    ? userState[StoreUserDataStateName]
    : null;
};
export const selectUserIsLoggedIn: StoreSelectorBase<boolean> = (s): boolean =>
  !!selectUserData(s);
