import React, { useEffect, useState } from "react";
import { Text, Spacer, Button, Dialog } from "../common";
import APIService from "../../services/api.service";
import TableChildRowMerchApproveItem from "./TableChildRowMerchApprovementItem";
import useDispatchError from "../../shared/hooks/useDispatchError";

const TableChildRowMerch = ({ merch, onSave }) => {
  const [setError] = useDispatchError();
  const [isDisabled, setIsDisabled] = useState(false);

  const results = merch?.results ?? [];
  const task = merch?.task;
  const pharmacy = merch?.userpharmacy?.pharmacy;

  const checkAlreadyApprovedResults = () => {
    const _approved = results?.filter(
      r => typeof r?.approved !== "undefined" && r?.approved,
    );
    return _approved?.map(_a => _a.id) ?? [];
  };
  const __approvedResults = checkAlreadyApprovedResults();
  const isDisabledSave =
    __approvedResults.length === results?.length && merch?.status !== "wait";
  const [handled, setHandled] = useState([...__approvedResults]);

  const handleAction = async (id, changes, isApproved) => {
    setIsDisabled(true);
    await APIService.putDataByIDHandler(
      `/taskmerchandisingresults`,
      id,
      {
        ...changes,
        approved: isApproved,
      },
      () => setError("Не удалось изменить статус."),
      () => setHandled([...handled, id]),
    );
    setIsDisabled(false);
  };
  const handleSaveAction = async () => {
    setIsDisabled(true);
    await APIService.putDataByIDHandler(
      `/taskmerchandisingusers`,
      merch.id,
      {
        status: "done",
      },
      () => setError("Не удалось сохранить данные."),
      () => onSave(),
    );
    setIsDisabled(false);
  };

  const [imagePreview, setImagePreview] = useState(null);
  const openImagePreview = imgRaw => setImagePreview(imgRaw);
  const closeImagePreview = () => setImagePreview(null);

  return (
    <>
      {results &&
        results.map(result => (
          <TableChildRowMerchApproveItem
            openImagePreview={openImagePreview}
            task={task}
            merch={result}
            pharmacy={pharmacy}
            key={result.id}
            onAction={(data, isApproved) =>
              handleAction(result.id, data, isApproved)
            }
            isNeedAction={!handled.includes(result.id)}
            resultsLength={results?.length}
            disabled={isDisabled}
          />
        ))}

      <Spacer top='2rem' />

      <div className='d-flex justify-end'>
        <Button
          type='primary'
          onClick={() => handleSaveAction()}
          disabled={
            isDisabledSave || handled?.length !== results?.length || isDisabled
          }>
          <Text type='sm'>Сохранить</Text>
        </Button>
        <Spacer left='2rem' />
      </div>

      <Spacer top='2rem' />

      <Dialog show={!!imagePreview} close={closeImagePreview}>
        <img
          src={imagePreview}
          alt={"Превью картинка"}
          className='approvement-page-preview-image'
          onClick={e => {
            e.preventDefault();
            closeImagePreview();
          }}
        />
      </Dialog>
    </>
  );
};

export default TableChildRowMerch;
