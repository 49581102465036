import React from "react";
import { Link, Route, Switch } from "react-router-dom";
import TasksTable from "../components/tasks/TasksTable";
import TaskModal from "../components/tasks/modal/TaskModal";
import Layout from "../components/layout/Layout";
import { Icon, Button, Spacer, Text } from "../components/common";
import {
  ROUTE_TASKS_PARAMS,
  ROUTE_TASKS_EDIT,
  ROUTE_TASKS_NEW,
} from "../AppRoutes";
import APIService, { API_DATA_REPORT_SEND } from "../services/api.service";
import useDispatchError from "../shared/hooks/useDispatchError";
import {
  StoreAppErrorStyleSuccess,
  StoreAppError_SuccessServerResponse,
} from "../store/app";

const TasksPage = () => {
  const [setError] = useDispatchError();

  const onClickUploadReport = async () => {
    const resp = await APIService.postDataHandler(
      API_DATA_REPORT_SEND,
      {},
      () => setError("Не удалось отправит запрос на формирование отчетов."),
      resp => {
        setError(
          `Начат сбор отчетов. Они будут отправлены по адресу ${resp.data.email}`,
          StoreAppError_SuccessServerResponse,
          StoreAppErrorStyleSuccess,
        );
      },
    );
  };

  return (
    <Layout
      topSlot={
        <div className='d-flex'>
          <Button
            type='primary'
            onClick={e => {
              e?.preventDefault();
              onClickUploadReport();
            }}>
            <Icon icon='download_white' size='2rem' className='grey' />
            <Spacer left='0.5rem' />
            <Text type='sm' color='white'>
              Загрузить отчет
            </Text>
          </Button>

          <Spacer right='2rem' />

          <Link to={ROUTE_TASKS_NEW}>
            <Button type='primary'>
              <Icon icon='add' size='2rem' />
              <Spacer left='0.5rem' />
              <Text type='sm' color='white'>
                Создать
              </Text>
            </Button>
          </Link>
        </div>
      }>
      <div className='faq-page'>
        <Switch>
          <Route path={ROUTE_TASKS_EDIT}>
            <TaskModal />
          </Route>
          <Route path={ROUTE_TASKS_PARAMS}>
            <TasksTable />
          </Route>
        </Switch>
      </div>
    </Layout>
  );
};

export default TasksPage;
