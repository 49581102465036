import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { Text, Spacer, Select, Checkbox, Button, Icon, Modal } from "../common";

import useFormData, {
  useFormDataPropNameRoot,
  useFormDataPropSelectDefault,
  useFormData_UserRoleAdmin,
  useFormData_UserRoleAgent,
  useFormData_UserRolePharmacy,
  useFormData_UserRolesValid,
} from "../../shared/hooks/useFormData";
import APIService, {
  API_DATA_ROLES,
  API_DATA_USERS,
} from "../../services/api.service";
import {
  ROUTE_USERS,
  ROUTE_USERS_ADMIN,
  ROUTE_USERS_MEDICAL_MEMBERS,
  ROUTE_USERS_PHARMACY,
} from "../../AppRoutes";
import useDispatchError from "../../shared/hooks/useDispatchError";
import { StoreAppErrorTypeApp, StoreAppErrorTypeForm } from "../../store/app";
import { useFormDataTextRequired } from "../../shared/hooks/useFormData";
import {
  UserAdminForm,
  UserAgentForm,
  UserPharmacyForm,
} from "./UserModalForms";
import {
  _defaultFormRoles,
  _formOptionsDefault,
  _formOptionsFetchProps,
} from "./_userModalProps";
import { selectUserData } from "../../store/user";

const UserModal = () => {
  const [setError] = useDispatchError();
  const __userData = useSelector(selectUserData);
  const history = useHistory();
  const { id } = useParams();
  const [formData, onChangeFormData, onSubmit, fetchedUserData] = useFormData({
    id: id,
    dataName: API_DATA_USERS,
  });

  const isNew = !id;

  const [disabledActions, setDisabledActions] = useState(false);

  const [roleOptions, setRoleOptions] = useState([]);
  const onChangeRole = value => {
    let opt = roleOptions.find(o => o.value === value);
    if (!opt?.value) {
      opt = { ...useFormDataPropSelectDefault };
    }
    onChangeFormData(opt, "role");
  };

  const __onChangeRoleForm = (value, valueName, role) => {
    const roleData = formData[role];
    const _data = typeof value === "object" ? value : { value };

    const _updateData = {
      ...roleData,
      [valueName]: {
        ...roleData[valueName],
        ..._data,
      },
    };

    onChangeFormData(_updateData, role);
  };
  const onChangeAdminForm = (value, valueName) =>
    __onChangeRoleForm(value, valueName, useFormData_UserRoleAdmin);
  const onChangeAgentForm = (value, valueName) =>
    __onChangeRoleForm(value, valueName, useFormData_UserRoleAgent);
  const onChangePharmacyForm = (value, valueName) =>
    __onChangeRoleForm(value, valueName, useFormData_UserRolePharmacy);

  const [formOptions, setFormOptions] = useState({ ..._formOptionsDefault });
  const __onChangeRoleFormOptions = (opts, optsName, role) => {
    const roleOpts = formOptions[role];
    setFormOptions({
      ...formOptions,
      [role]: { ...roleOpts, [optsName]: [...opts] },
    });
  };
  const onChangeAgentFormOptions = (opts, optsName) =>
    __onChangeRoleFormOptions(opts, optsName, useFormData_UserRoleAgent);
  const onChangePharmacyFormOptions = (opts, optsName) =>
    __onChangeRoleFormOptions(opts, optsName, useFormData_UserRolePharmacy);

  const onChangeLocationsRemove = locationObject => {
    const filteredFormLocations = formData[
      useFormData_UserRoleAgent
    ]?.pharmacies.value.filter(fl => fl.id !== locationObject.id);

    onChangeAgentForm(
      {
        value: [...filteredFormLocations],
      },
      "pharmacies",
    );

    const newLocationOpts = [
      ...formOptions[useFormData_UserRoleAgent].pharmacies,
      {
        ...locationObject,
        action: (
          <Icon
            icon='plus-outline_blue'
            size='2.25rem'
            onClick={() => onChangeLocationsAdd(locationObject)}
          />
        ),
      },
    ];
    onChangeAgentFormOptions(newLocationOpts, "pharmacies");
  };
  const onChangeLocationsAdd = locationObject => {
    const filteredOpts = formOptions[
      useFormData_UserRoleAgent
    ].pharmacies.filter(ol => ol.id !== locationObject?.id);

    const formLocations = formData[useFormData_UserRoleAgent]?.pharmacies.value;
    const newLocationsValue = {
      value: [
        ...formLocations,
        {
          ...locationObject,
          action: (
            <Icon
              icon='remove'
              size='2.25rem'
              onClick={() => onChangeLocationsRemove(locationObject)}
            />
          ),
        },
      ],
    };

    onChangeAgentForm(newLocationsValue, "pharmacies");
    onChangeAgentFormOptions(filteredOpts, "pharmacies");
  };
  const _getMappedLocationOptionsAdders = options =>
    options.map(l => {
      const { action, ...locationObject } = l;
      return {
        ...locationObject,
        action: (
          <Icon
            icon='plus-outline_blue'
            size='2.25rem'
            onClick={() => onChangeLocationsAdd(locationObject)}
          />
        ),
      };
    });
  const _getMappedLocationOptionsRemovers = options =>
    options.map(l => {
      const { action, ...locationObject } = l;
      return {
        ...locationObject,
        action: (
          <Icon
            icon='remove-red'
            size='2.25rem'
            onClick={() => onChangeLocationsRemove(locationObject)}
          />
        ),
      };
    });

  const _onChangePharmacyAgentsRemove = agentObject => {
    const filteredFormAgents = formData[
      useFormData_UserRolePharmacy
    ]?.agents?.value?.filter(fl => fl.id !== agentObject.id);

    onChangePharmacyForm(
      {
        value: [...filteredFormAgents],
      },
      "agents",
    );

    const newLocationOpts = [
      ...formOptions[useFormData_UserRolePharmacy]?.agents,
      {
        ...agentObject,
        action: (
          <Icon
            icon='plus-outline_blue'
            size='2.25rem'
            onClick={() => _onChangePharmacyAgentsAdd(agentObject)}
          />
        ),
      },
    ];
    onChangePharmacyFormOptions(newLocationOpts, "agents");
  };
  const __onPharmacyAgentAddRules = agentObject => {
    const _pharmacyAgentsData =
      formData[useFormData_UserRolePharmacy]?.agents?.value ?? [];
    if (_pharmacyAgentsData?.length >= 3) {
      setError(
        "Нельзя выбрать больше 3-х Медицинских представителей.",
        StoreAppErrorTypeForm,
      );
      return false;
    }

    const _validOTCLines = ["OTC PHL", "OTC MIX"];
    const _validPHLLines = ["RX PHL", "RX MIX"];

    const ___onPharmacyAgentAddMPLineRules = (
      categoryName,
      categoryOptions,
    ) => {
      if (!categoryOptions.includes(agentObject?.secondaryText ?? "")) {
        return true;
      }

      const _alreadyAddedAgentData = _pharmacyAgentsData.find(
        pad => pad?.secondaryText === agentObject?.secondaryText,
      );

      if (_alreadyAddedAgentData) {
        setError(
          `Линия МП из категории ${categoryName}(${categoryOptions.toString()}) уже была выбрана(${
            _alreadyAddedAgentData.primaryText
          } ${
            _alreadyAddedAgentData.secondaryText
          }), не возможно добавить сразу несколько линий этой категории.`,
          StoreAppErrorTypeForm,
        );
        return false;
      }

      return true;
    };

    if (!___onPharmacyAgentAddMPLineRules("OTC", _validOTCLines)) {
      return false;
    }
    if (!___onPharmacyAgentAddMPLineRules("PHL", _validPHLLines)) {
      return false;
    }

    return true;
  };
  const _onChangePharmacyAgentsAdd = agentObject => {
    if (!__onPharmacyAgentAddRules(agentObject)) {
      return;
    }

    const formAgents =
      formData[useFormData_UserRolePharmacy]?.agents?.value ?? [];
    const newAgentsValue = {
      value: [
        ...formAgents,
        {
          ...agentObject,
          action: (
            <Icon
              icon='remove'
              size='2.25rem'
              onClick={() => _onChangePharmacyAgentsRemove(agentObject)}
            />
          ),
        },
      ],
    };
    onChangePharmacyForm(newAgentsValue, "agents");

    const filteredOpts = formOptions[
      useFormData_UserRolePharmacy
    ].agents.filter(ol => ol.id !== agentObject?.id);
    onChangePharmacyFormOptions(filteredOpts, "agents");
  };
  const _getMappedAgentsOptionsAdders = options =>
    options.map(a => {
      const { action, ...agentObject } = a;
      return {
        ...agentObject,
        action: (
          <Icon
            icon={
              formData[useFormData_UserRolePharmacy]?.agents?.value?.length >= 3
                ? "plus-outline"
                : "plus-outline_blue"
            }
            size='2.25rem'
            onClick={() => _onChangePharmacyAgentsAdd(agentObject)}
          />
        ),
      };
    });
  const _getMappedAgentsOptionsRemovers = options =>
    options.map(a => {
      const { action, ...agentObject } = a;
      return {
        ...agentObject,
        action: (
          <Icon
            icon='remove-red'
            size='2.25rem'
            onClick={() => _onChangePharmacyAgentsRemove(agentObject)}
          />
        ),
      };
    });

  let isFetchData = !!formData;
  if (id && isFetchData) {
    isFetchData = !!fetchedUserData;
  }

  useEffect(() => {
    const fetchRolesIDs = async () => {
      const userRoles = await APIService.getDataHandler(API_DATA_ROLES, () =>
        setError("Не удалось получить типы пользователей."),
      ).then(data => data?.roles);
      if (!userRoles) {
        return;
      } else if (!userRoles?.length) {
        setError("Нет типов пользователей.");
        return;
      }

      const newRoleOptions = [];
      for (const r of userRoles) {
        if (!useFormData_UserRolesValid.includes(r.type)) {
          continue;
        }

        const defaultOpt = _defaultFormRoles.find(t => t.value === r.type);
        const newOpt = { ...defaultOpt, id: r.id };
        newRoleOptions.push(newOpt);
      }

      if (
        !newRoleOptions?.length ||
        newRoleOptions.length !== useFormData_UserRolesValid.length
      ) {
        setError("Что-то не так с типами пользователей.", StoreAppErrorTypeApp);
      } else {
        setRoleOptions([useFormDataPropSelectDefault, ...newRoleOptions]);
      }
    };

    const fetchOptions = async () => {
      const defaultOptsMapper = (opts, isDefaultOpt = true) => {
        const __mapper = o => ({ value: o.name, text: o.name, id: o.id });

        let __resOpts = [];
        if (opts.length) {
          __resOpts = opts.map(__mapper);
        }
        if (isDefaultOpt) {
          __resOpts = [useFormDataPropSelectDefault, ...__resOpts];
        }

        return __resOpts;
      };

      let newFormOptions = {};
      const addNewRoleOptions = (roleName, optsName, opts) => {
        if (!newFormOptions[roleName]) {
          newFormOptions[roleName] = {};
        }

        const prevFormOpts = {
          ...formOptions[roleName],
          ...newFormOptions[roleName],
        };

        newFormOptions[roleName] = {
          ...prevFormOpts,
          [optsName]: [...opts],
        };
      };

      let __fetchedOpts = {};

      for (const optsRoleName of Object.keys(_formOptionsFetchProps)) {
        const optsRoleData = _formOptionsFetchProps[optsRoleName];
        for (const optsName of Object.keys(optsRoleData)) {
          const optsData = optsRoleData[optsName];
          let newOpts = [];

          const __fetchedOptsName = optsData.APIDataName + optsData.APIQuery;

          if (__fetchedOpts[__fetchedOptsName]) {
            newOpts = __fetchedOpts[__fetchedOptsName];
          } else {
            newOpts = await APIService.getDataHandler(
              optsData.APIDataName,
              () =>
                setError(`Не удалось получить опции для '${optsData.label}'.`),
              0,
              100,
              optsData.APIQuery,
            );

            if (!newOpts) {
              return;
            }
            __fetchedOpts[__fetchedOptsName] = newOpts;
          }

          switch (optsRoleName) {
            case useFormData_UserRolePharmacy:
            case useFormData_UserRoleAgent: {
              switch (optsName) {
                case "agents":
                  const agentsOpts = newOpts.map(o => ({
                    id: o.id,
                    primaryText: `${o?.firstname ?? "Не"} ${
                      o?.lastname ?? "указано"
                    }`,
                    secondaryText: o?.agent?.agentline?.name ?? "Неизвестно",
                  }));
                  addNewRoleOptions(optsRoleName, optsName, agentsOpts);
                  break;

                case "pharmacies":
                  const pharmaciesOpts = newOpts.map(o => ({
                    id: o?.id,
                    primaryText: o?.pharmacy?.address,
                    secondaryText: o?.pharmacy?.pharmacyname,
                  }));
                  addNewRoleOptions(optsRoleName, optsName, pharmaciesOpts);
                  break;

                case "cities":
                case "commands":
                  addNewRoleOptions(
                    optsRoleName,
                    optsName,
                    defaultOptsMapper(newOpts, false),
                  );
                  break;

                default:
                  addNewRoleOptions(
                    optsRoleName,
                    optsName,
                    defaultOptsMapper(newOpts),
                  );
                  break;
              }
              break;
            }

            default:
              addNewRoleOptions(
                optsRoleName,
                optsName,
                defaultOptsMapper(newOpts),
              );
              break;
          }
        }
      }

      setFormOptions({ ...formOptions, ...newFormOptions });
    };

    const parseUserData = () => {
      const fetchedUserRole = fetchedUserData?.role;
      const matchRole = _defaultFormRoles.find(
        t => t.value === fetchedUserRole?.type,
      );
      if (!matchRole || !fetchedUserRole) {
        setError(
          `Неизвестная роль пользователя(${
            fetchedUserRole.type ?? "Информация о пользователе не валидна"
          }).`,
          StoreAppErrorTypeApp,
        );
        return;
      }

      const fetchedUserRoleName = matchRole.value;
      let __userData = {
        role: { ...formData?.role, ...matchRole, id: fetchedUserRole.id },
      };

      switch (fetchedUserRoleName) {
        case useFormData_UserRoleAdmin: {
          const formRoleData = formData[useFormData_UserRoleAdmin];

          let adminData = {};
          for (const frdKey of Object.keys(formRoleData)) {
            const frd = formRoleData[frdKey];
            let value = frd.value;

            switch (frdKey) {
              case "fullname":
                value = `${fetchedUserData.firstname ?? ""}${
                  fetchedUserData.lastname ? ` ${fetchedUserData.lastname}` : ""
                }`;
                break;

              default:
                value = fetchedUserData[frdKey] ?? "";
                break;
            }

            adminData[frdKey] = { ...frd, value };
          }

          __userData[useFormData_UserRoleAdmin] = {
            ...formRoleData,
            ...adminData,
          };
          break;
        }

        case useFormData_UserRoleAgent: {
          const formRoleData = formData[useFormData_UserRoleAgent];

          let agentData = {};
          for (const frdKey of Object.keys(formRoleData)) {
            const frd = formRoleData[frdKey];
            let value = frd.value;
            let additional = {};

            switch (frdKey) {
              case "fullname":
                value = `${fetchedUserData.firstname ?? ""}${
                  fetchedUserData.lastname ? ` ${fetchedUserData.lastname}` : ""
                }`;
                break;

              case "agentline":
              case "city":
              case "command": {
                const selectionData = fetchedUserData?.agent[frdKey];
                value = selectionData?.name;
                additional = {
                  text: selectionData?.name,
                  id: selectionData?.id,
                };
                break;
              }

              case "pharmacies":
                const userPharmacies = fetchedUserData?.agent?.pharmacies;

                value = [];

                if (userPharmacies?.length) {
                  for (const uph of userPharmacies) {
                    const uphData = uph.pharmacy;
                    const locationObject = {
                      id: uph?.id,
                      primaryText: uphData?.address,
                      secondaryText: uphData?.pharmacyname,
                    };
                    value.push(locationObject);
                  }
                }
                break;

              default:
                value = fetchedUserData[frdKey] ?? "";
                break;
            }

            agentData[frdKey] = { ...frd, value, ...additional };
            additional = {};
          }

          __userData[useFormData_UserRoleAgent] = {
            ...formRoleData,
            ...agentData,
          };
          break;
        }

        case useFormData_UserRolePharmacy: {
          const formRoleData = formData[useFormData_UserRolePharmacy];

          let pharmacyData = {};
          for (const frdKey of Object.keys(formRoleData)) {
            const frd = formRoleData[frdKey];
            let value = frd.value;
            let additional = {};

            switch (frdKey) {
              case "fullname":
                value = `${fetchedUserData.firstname ?? ""}${
                  fetchedUserData.lastname ? ` ${fetchedUserData.lastname}` : ""
                }`;
                break;

              case "city":
                const selectionData = fetchedUserData?.pharmacy?.city;
                if (!selectionData) {
                  continue;
                }

                value = selectionData?.name;
                additional = {
                  text: selectionData?.name,
                  id: selectionData?.id,
                };
                break;

              case "contract":
              case "entityname":
              case "entityphone":
              case "bin":
                value = fetchedUserData?.pharmacy?.entity[frdKey];

                break;

              case "category":
              case "pharmacyname":
              case "address":
              case "type":
              case "main":
                value = fetchedUserData?.pharmacy[frdKey];
                additional = {
                  text: value,
                };
                break;

              case "agents":
                const agents = fetchedUserData?.pharmacy?.agents;
                if (!agents?.length) {
                  continue;
                }

                value = agents.map(a => ({
                  id: a.id,
                  primaryText: `${a.firstname} ${a.lastname}`,
                  secondaryText: a?.agent?.agentline?.name,
                }));

                break;

              default:
                value = fetchedUserData[frdKey] ?? "";
                break;
            }

            pharmacyData[frdKey] = { ...frd, value, ...additional };
            additional = {};
          }

          __userData[useFormData_UserRolePharmacy] = {
            ...formRoleData,
            ...pharmacyData,
          };
          break;
        }

        default:
          setError(
            `Не возможно отредактировать пользователя с ролью '${fetchedUserRoleName}'.`,
            StoreAppErrorTypeApp,
          );
          return;
      }

      onChangeFormData({ ...formData, ...__userData }, useFormDataPropNameRoot);
    };

    if (isFetchData) {
      fetchRolesIDs();
      fetchOptions();
      if (fetchedUserData) {
        parseUserData();
      }
    }
  }, [isFetchData, fetchedUserData]);

  const renderUserRoleForm = () => {
    if (!isFetchData) {
      return null;
    }

    switch (formData?.role?.value) {
      case useFormData_UserRoleAgent:
        return (
          <UserAgentForm
            formData={formData[useFormData_UserRoleAgent]}
            onChange={onChangeAgentForm}
            formOptions={formOptions[useFormData_UserRoleAgent]}
            onChangeOptions={onChangeAgentFormOptions}
            locationMapperAdders={_getMappedLocationOptionsAdders}
            locationMapperRemovers={_getMappedLocationOptionsRemovers}
          />
        );
      case useFormData_UserRolePharmacy:
        return (
          <UserPharmacyForm
            formData={formData[useFormData_UserRolePharmacy]}
            onChange={onChangePharmacyForm}
            formOptions={formOptions[useFormData_UserRolePharmacy]}
            onChangeOptions={onChangePharmacyFormOptions}
            agentMapperAdders={_getMappedAgentsOptionsAdders}
            agentMapperRemovers={_getMappedAgentsOptionsRemovers}
            onChangeFormData={onChangeFormData}
            isNew={isNew}
          />
        );
      case useFormData_UserRoleAdmin:
        return (
          <UserAdminForm
            formData={formData[useFormData_UserRoleAdmin]}
            onChange={onChangeAdminForm}
          />
        );
      default:
        return null;
    }
  };

  const onDeleteUser = async () => {
    const deletedUser = await APIService.delDataByIDHandler(
      API_DATA_USERS,
      id,
      () => setError("Не удалось удалить пользователя"),
    );
    if (deletedUser) {
      history.push(ROUTE_USERS);
    }
  };

  const __onSubmitErrParser = err => {
    const errData = err?.response?.data;
    let errID = undefined;
    if (errData && errData?.data?.length && errData?.data[0]?.messages[0]?.id) {
      errID = errData?.data[0]?.messages[0]?.id;
    }

    let errMsg = "Не удалось создать нового пользователя.";

    switch (errID) {
      case "Auth.form.error.email.taken":
        errMsg = `E-mail не корректен или уже занят.`;
        break;

      case "Auth.form.error.username.taken":
        errMsg = `Телефон не корректен или уже занят.`;
        break;

      default:
        break;
    }

    setError(errMsg);
  };
  const __onSubmitParseFullname = fullname => {
    const fullnameValue = fullname.trim();
    const splittedName = fullnameValue.split(" ");
    if (2 > splittedName?.length) {
      setError(
        `Убедитесь что в 'Имя и фамилия' нет лишних пробелов и вы ввели как минимум 2 слова (Имя И Фамилия).`,
        StoreAppErrorTypeForm,
      );
      return;
    }

    const firstname = splittedName.splice(0, 1)[0];
    let lastname = "";
    for (const n of splittedName) {
      lastname += `${n} `;
    }
    lastname = lastname.trim();

    return { lastname, firstname };
  };
  const onSubmitAdmin = async (adminData, roleID) => {
    let uploadData = {
      role: roleID,
      status: fetchedUserData?.status ?? "new",
    };
    let isNoErr = true;

    for (const adKey of Object.keys(adminData)) {
      switch (adKey) {
        case "fullname": {
          const names = __onSubmitParseFullname(adminData[adKey].value);
          if (!names) {
            isNoErr = false;
            break;
          }

          uploadData = { ...uploadData, ...names };
          break;
        }

        default:
          uploadData[adKey] = adminData[adKey]?.value;
          break;
      }

      if (!isNoErr) {
        return;
      }
    }

    if (isNoErr) {
      return uploadData;
    }
  };
  const onSubmitAgent = async (agentData, roleID) => {
    let uploadData = {
      role: roleID,
      status: fetchedUserData?.status ?? "new",
    };
    let isNoErr = true;

    for (const adKey of Object.keys(agentData)) {
      const ad = agentData[adKey];
      if (!ad?.required && !ad?.value?.length && adKey !== "pharmacies") {
        continue;
      }

      switch (adKey) {
        case "fullname": {
          const names = __onSubmitParseFullname(ad.value);
          if (!names) {
            isNoErr = false;
          } else {
            uploadData = { ...uploadData, ...names };
          }
          break;
        }

        case "agentline":
        case "command":
        case "city":
          if (!ad?.id) {
            isNoErr = false;
            setError(`Пропущено поле '${ad.label}'.`, StoreAppErrorTypeForm);
          } else {
            uploadData[adKey] = ad?.id;
          }
          break;

        case "pharmacies":
          if (!ad?.required && !ad?.value?.length) {
            continue;
          }

          if (!ad?.value?.length) {
            setError("Пропущено поле 'Локации'.", StoreAppErrorTypeForm);
            isNoErr = false;
            break;
          }
          uploadData[adKey] = ad?.value.map(l => l.id);
          break;

        default:
          uploadData[adKey] = ad?.value;
          break;
      }

      if (!isNoErr) {
        return;
      }
    }

    if (isNoErr) {
      return uploadData;
    }
  };
  const onSubmitPharmacy = async (pharmacyData, roleID) => {
    let uploadData = {
      role: roleID,
      status: fetchedUserData?.status ?? "new",
      creator: __userData.id,
    };
    let isNoErr = true;

    for (const pdKey of Object.keys(pharmacyData)) {
      const pd = pharmacyData[pdKey];
      if (
        !pd?.required &&
        !pd?.value?.length &&
        pdKey !== "agents" &&
        pdKey !== "main" &&
        pdKey !== "entityID"
      ) {
        continue;
      }

      switch (pdKey) {
        case "fullname":
          let names = {};
          if (!pd?.value?.length) {
            names.firstname = "";
            names.lastname = "";
          } else {
            names = __onSubmitParseFullname(pd.value);
          }

          if (!names) {
            isNoErr = false;
          } else {
            uploadData = { ...uploadData, ...names };
          }
          break;

        case "city":
          if (!pd?.id) {
            isNoErr = false;
            setError(`Пропущено поле '${pd.label}'.`, StoreAppErrorTypeForm);
          } else {
            uploadData[pdKey] = pd?.id;
          }
          break;

        case "agents":
          if (pd?.required && !pd?.value?.length) {
            setError(
              "Пропущено поле 'Медицинские представители'.",
              StoreAppErrorTypeForm,
            );
            isNoErr = false;
            break;
          }
          if (pd?.value?.length) {
            uploadData[pdKey] = pd?.value.map(a => a.id);
          }
          break;
          break;

        case "main":
          uploadData[pdKey] = pd?.value;
          break;

        case "entityID":
          if (pd) {
            uploadData.entity = pd;
          }
          break;

        default:
          if (!pd?.value?.length) {
            isNoErr = false;
            setError(`Пропущено поле '${pd.label}'.`, StoreAppErrorTypeForm);
          } else {
            uploadData[pdKey] = pd?.value;
          }
          break;
      }

      if (!isNoErr) {
        return;
      }
    }

    if (isNoErr) {
      return uploadData;
    }
  };
  const onSubmitHandler = async () => {
    if (disabledActions) {
      return;
    } else {
      setDisabledActions(true);
    }

    const isSuccess = await onSubmit();
    if (!isSuccess) {
      setDisabledActions(false);
      return;
    }

    let uploadData = undefined;
    let redirectRoute = ROUTE_USERS_MEDICAL_MEMBERS;

    switch (formData?.role?.value) {
      case useFormData_UserRoleAdmin:
        uploadData = await onSubmitAdmin(
          formData[useFormData_UserRoleAdmin],
          formData?.role?.id,
        );
        redirectRoute = ROUTE_USERS_ADMIN;
        break;

      case useFormData_UserRoleAgent:
        uploadData = await onSubmitAgent(
          formData[useFormData_UserRoleAgent],
          formData?.role?.id,
        );
        redirectRoute = ROUTE_USERS_MEDICAL_MEMBERS;
        break;

      case useFormData_UserRolePharmacy:
        uploadData = await onSubmitPharmacy(
          formData[useFormData_UserRolePharmacy],
          formData?.role?.id,
        );
        redirectRoute = ROUTE_USERS_PHARMACY;
        break;

      default:
        setError(
          `Не возможно создать тип пользователя: '${formData?.role?.text}'.`,
          StoreAppErrorTypeApp,
        );
        break;
    }

    if (uploadData) {
      let newUser = undefined;

      if (isNew) {
        newUser = await APIService.postDataHandler(
          API_DATA_USERS,
          uploadData,
          __onSubmitErrParser,
        );
      } else {
        newUser = await APIService.putDataByIDHandler(
          API_DATA_USERS,
          id,
          uploadData,
          __onSubmitErrParser,
        );
      }

      if (newUser) {
        history.push(redirectRoute);
      } else {
        setDisabledActions(false);
      }
    } else {
      setDisabledActions(false);
    }
  };

  return (
    <Modal back={() => history.goBack()} title='Создание нового пользователя'>
      <form
        onSubmit={e => {
          e.preventDefault();
          onSubmitHandler();
        }}>
        <div className='d-flex align-start'>
          <div className='flex-50 '>
            <div className='d-flex align-center'>
              <Text color='secondary' type='md' noWrap>
                {useFormDataTextRequired(
                  formData?.role?.label,
                  formData?.role?.required,
                )}
              </Text>
              <Spacer left='auto' />
              <Select
                disabled={!roleOptions?.length || !formData}
                required={formData?.role?.required}
                value={formData?.role?.value}
                onChange={e => onChangeRole(e.target.value)}
                variants={roleOptions}
              />
            </div>

            {formData?.role?.value === useFormData_UserRolePharmacy && (
              <div style={{ marginBottom: "-3rem" }}>
                <Spacer top='0.5rem' />
                <Checkbox
                  required={
                    formData[useFormData_UserRolePharmacy]?.main?.required
                  }
                  value={formData[useFormData_UserRolePharmacy]?.main?.value}
                  onChange={() =>
                    onChangePharmacyForm(
                      !formData[useFormData_UserRolePharmacy]?.main?.value,
                      "main",
                    )
                  }
                  label={
                    <Text color='grey' type='md' noWrap>
                      {useFormDataTextRequired(
                        formData[useFormData_UserRolePharmacy]?.main?.label,
                        formData[useFormData_UserRolePharmacy]?.main?.required,
                      )}
                    </Text>
                  }
                />
              </div>
            )}
          </div>

          <Spacer left='4rem' />

          <div className='flex-50 d-flex align-center'>
            {formData?.role?.value === useFormData_UserRolePharmacy && (
              <>
                <Text color='secondary' type='md' noWrap>
                  {useFormDataTextRequired(
                    formData[useFormData_UserRolePharmacy]?.type?.label,
                    formData[useFormData_UserRolePharmacy]?.type?.required,
                  )}
                </Text>
                <Spacer left='auto' />
                <Select
                  onChange={e => {
                    const __newValue = e.target.value;

                    const onChangeSelect = (value, valueName, options) => {
                      let opt = options.find(o => o?.value === value);
                      if (!opt?.value) {
                        opt = { ...useFormDataPropSelectDefault };
                      }
                      onChangePharmacyForm(opt, valueName);
                    };

                    onChangeSelect(
                      __newValue,
                      "type",
                      formOptions[useFormData_UserRolePharmacy]?.types,
                    );
                  }}
                  disabled={
                    !formOptions[useFormData_UserRolePharmacy]?.types?.length
                  }
                  required={
                    formData[useFormData_UserRolePharmacy]?.type?.required
                  }
                  value={formData[useFormData_UserRolePharmacy]?.type?.value}
                  label='Выберите тип аптеки'
                  variants={formOptions[useFormData_UserRolePharmacy]?.types}
                />
              </>
            )}
          </div>
        </div>

        <hr className='line' />

        {renderUserRoleForm()}

        <div className='flex-1'></div>

        <Spacer top='1.5rem' />

        <div className='d-flex justify-end'>
          <Button
            type='outline'
            onClick={() => {
              if (isNew) {
                history.push(ROUTE_USERS);
              } else {
                onDeleteUser();
              }
            }}
            disabled={disabledActions}>
            <Icon icon='remove' size='2rem' />
            <Spacer left='1.5rem' />
            <Text type='md'>Удалить</Text>
          </Button>
          <Spacer left='3rem' />
          <Button type='primary' btnType='submit' disabled={disabledActions}>
            <Text type='md'>{isNew ? "Готово" : "Сохранить"}</Text>
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default UserModal;
