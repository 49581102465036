import TasksCard from "../components/dashboard/TasksCard";
import ApprovementCard from "../components/dashboard/ApprovementCard";
import UsersCard from "../components/dashboard/UsersCard";
import ShopCard from "../components/dashboard/ShopCard";
import DataCard from "../components/dashboard/DataCard";
import Layout from "../components/layout/Layout";
import { Button, Icon, Spacer, Text } from "../components/common";
import { useDispatch } from "react-redux";
import { userLogout } from "../store/user";

const MainPage = () => {
  const dispatch = useDispatch();
  const onClickLogout = e => {
    e?.preventDefault();
    dispatch(userLogout());
  };

  return (
    <Layout
      topSlot={
        <Button type='outline' onClick={onClickLogout}>
          <Icon icon='logout-red' size='2rem' />
          <Spacer left='0.5rem' />
          <Text type='sm' color='white'>
            Выход
          </Text>
        </Button>
      }>
      <div className='main-page'>
        <div className='dashboard-row'>
          <TasksCard />
          <Spacer left="3rem" />
          <ApprovementCard />
        </div>
        <div className='dashboard-row'>
          <UsersCard />
          <Spacer left="3rem" />
          <ShopCard />
          <Spacer left="3rem" />
          <DataCard />
        </div>
      </div>
    </Layout>
  );
};

export default MainPage;
