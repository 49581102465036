import React, { useState } from "react";
import { useHistory, useParams } from "react-router";
import { ROUTE_NOTIFICATIONS } from "../../AppRoutes";
import APIService, { API_DATA_NOTIFICATIONS } from "../../services/api.service";
import useDispatchError from "../../shared/hooks/useDispatchError";
import useFormData, {
  useFormDataTextRequired,
} from "../../shared/hooks/useFormData";

import { Text, Spacer, TextArea, Button, Icon, Modal, Radio } from "../common";

const NotificationsModal = ({ isNew }) => {
  let history = useHistory();
  const [setError] = useDispatchError();
  const { id } = useParams();
  const _isNew = isNew && !id;

  const [disabledActions, setDisabledActions] = useState(false);

  const [formData, onChangeData, onSubmit] = useFormData({
    id: id,
    dataName: API_DATA_NOTIFICATIONS,
  });
  const onChangeInput = (value, valueName) => {
    onChangeData(value, valueName);
  };
  const onDelete = async e => {
    e.preventDefault();

    if (!_isNew) {
      await APIService.delDataByIDHandler(API_DATA_NOTIFICATIONS, id, () =>
        setError(`Не удалось удалить Уведомление(${id})`),
      );
    }

    history.push(ROUTE_NOTIFICATIONS);
  };

  const onSubmitHandler = async e => {
    if (disabledActions) {
      return;
    } else {
      setDisabledActions(true);
    }

    e.preventDefault();
    const newNotification = await onSubmit();

    if (newNotification) {
      history.push(ROUTE_NOTIFICATIONS);
    } else {
      setDisabledActions(false);
    }
  };

  const notificationTypesOptions = [
    { label: "Все", value: "general" },
    { label: "Аптеки", value: "pharmacy" },
    { label: "Мед. представители", value: "agent" },
  ];

  return (
    <Modal
      back={() => history.push(ROUTE_NOTIFICATIONS)}
      title={isNew ? "Создать уведомление" : "Редактировать уведомление"}>
      <form onSubmit={onSubmitHandler}>
        <Text color='secondary' type='md'>
          {useFormDataTextRequired(
            formData?.type?.label,
            formData?.type?.required,
          )}
        </Text>
        <Spacer top='1.5rem' />
        <Radio
          value={formData?.type?.value}
          required={formData?.type?.required}
          onChange={val => onChangeInput(val, "type")}
          variants={notificationTypesOptions}
        />

        <Spacer top='2.25rem' />
        <Text color='secondary' type='md'>
          {useFormDataTextRequired(
            formData?.name?.label,
            formData?.name?.required,
          )}
        </Text>
        <Spacer top='1.5rem' />
        <TextArea
          value={formData?.name?.value}
          required={formData?.name?.required}
          onChange={e => onChangeInput(e.target.value, "name")}
          rowsMin='1'
          placeholder='Введите заголовок'
          style={{ width: "100%" }}
        />

        <Spacer top='2.25rem' />

        <Text color='secondary' type='md'>
          {useFormDataTextRequired(
            formData?.description?.label,
            formData?.description?.required,
          )}
        </Text>
        <Spacer top='1.5rem' />
        <TextArea
          value={formData?.description?.value}
          required={formData?.description?.required}
          onChange={e => onChangeInput(e.target.value, "description")}
          rowsMin='9'
          placeholder='Введите текст'
          style={{ width: "100%" }}
        />

        <Spacer top='4rem' />

        <div className='d-flex'>
          <Button type='outline' onClick={onDelete} disabled={disabledActions}>
            <Icon icon='remove' size='2rem' />
            <Spacer left='1.5rem' />
            <Text type='md'>Удалить</Text>
          </Button>
          <Spacer left='auto' />
          <Button type='primary' btnType='submit' disabled={disabledActions}>
            <Text type='md'>Сохранить</Text>
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default NotificationsModal;
