import React from "react";
import {
  useFormDataPropSelectDefault,
  useFormDataTextRequired,
  useFormData_TaskTypePurchase,
} from "../../../../../../shared/hooks/useFormData";
import { Text, Spacer, Select } from "../../../../../common";

const PurchaseForm = ({
  formData,
  onChangeFormData,
  formOptions,
  onChangeFormOptions,
}) => {
  const onChangePurchaseFormData = (value, valueName) =>
    onChangeFormData(value, valueName, useFormData_TaskTypePurchase);
  const onChangeHandler = (value, valueName) => {
    onChangePurchaseFormData(value, valueName, null);
  };
  const onChangeSelect = (value, valueName, options) => {
    let opt = options.find(o => o?.value === value);
    if (!opt?.value) {
      opt = { ...useFormDataPropSelectDefault };
    }
    onChangeHandler(opt, valueName);
  };

  return (
    <div className='d-flex align-center'>
      <div>
        <Text color='secondary' type='md'>
          {useFormDataTextRequired(
            formData?.month?.label,
            formData?.month?.required,
          )}
        </Text>
        <Text color='secondary' isBold>
          (Магазины)
        </Text>
      </div>
      <Spacer left='auto' />
      <Select
        style={{ width: "32.2rem" }}
        label={!formData?.month?.value?.length ? "01.01.21" : ""}
        value={formData?.month?.value}
        variants={formOptions?.months}
        disabled={!formOptions?.months}
        onChange={e =>
          onChangeSelect(e.target.value, "month", formOptions?.months)
        }
      />
    </div>
  );
};

export default PurchaseForm;
